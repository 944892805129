.error-page {
  @include container(none);
  background: $backgroundWhiteColor;
  background-size: cover;
  margin-top: -25px;
  padding: 70px 20px 50px;

  &--500,
  &--502,
  &--503,
  &--504 {
    background: url('/assets/tesdorpf/frontend-core/staticImages/error500.jpg') no-repeat 100%;
    background-size: cover;
  }

  &--400,
  &--404 {
    background: url('/assets/tesdorpf/frontend-core/staticImages/error404.jpg') no-repeat 100%;
    background-size: cover;
  }

  &__content {
    background: rgb(255 255 255 / .8);
    text-align: center;
    max-width: 100%;
    border-radius: 5px;
    padding: 20px 20px 30px;

    @media (min-width: $screen-tablet-landscape) {
      max-width: 45%;
      margin-left: 5%;
    }
  }

  &__logo {
    margin: 20px 0;
    max-width: 180px;

    @media (min-width: $screen-tablet-landscape) {
      max-width: 200px;
    }
  }

  &__headline {
    @include fontSize($fontSizeLarge);
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    font-weight: $fontWeightRegular;
    line-height: 130%;
  }

  &__search {
    position: relative;
    max-width: 500px;
    margin: 10px auto 20px;
  }

  &__text {
    @include fontSize($fontSizeMedium);
    color: $textBaseColor;
    margin: 10px 0 5px;
  }

  &__button {
    margin: 10px auto;
    max-width: 300px;
  }

  &__link {
    @include fontSize($fontSizeMedium);
    color: $textBaseColor;
    margin: 10px 0 5px;
    display: inline-block;
  }
}
